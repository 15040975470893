import {
  Answer,
  AnswerDataTypeName,
  AnswerItem,
  Answers,
  CalculatedRiskAnswersResult,
  ColumnType,
  FileAnswerValue,
  FileViewAnswerValue,
  MultiChoiceAnswerValue,
  QScore,
  QuestionResult,
  QuestionRiskData,
  Tag
} from 'api/saq/types';
import { SaqUrls } from 'api/saq/resourcesUrl';

export const getSubcategory = (tag?: Tag) => {
  switch (tag?.subCategory as string) {
    case 'LABOUR_STANDARDS_AND_RIGHTS':
      return 'LABOUR STANDARDS';
    case 'HEALTH_AND_SAFETY':
      return 'HEALTH AND SAFETY';
    case 'MANAGEMENT_CONTROLS':
      return 'MANAGEMENT CONTROLS';
    case 'SITE_CHARACTERISTICS':
      return 'SITECHARACTERISTICS';
    default:
      return '';
  }
};

export const getCategory = (
  questionScore: QScore,
  columnProperty: ColumnType
) => {
  switch (columnProperty) {
    case 'PILLAR':
      return getSubcategory(
        questionScore.tags.find(
          (tag) => tag.category === (columnProperty as string)
        )
      );
    case 'IS_SAQ':
      return !questionScore.id.startsWith('employee.') ? 'SAQ' : 'Workers Info';
    case 'QUESTION':
      return questionScore.label;
    case 'SUBSECTION_NAME':
      return questionScore.subsection;
    default:
      return 'Empty';
  }
};

const getAnswer = (
  possibleAnswers: Answer[],
  answerResponse: Answers | undefined,
  questionId: string
): string[] | FileViewAnswerValue[] => {
  const answerData = answerResponse?.answers.find(
    (answer) => answer.questionCode === questionId
  )?.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.MULTICHOICE: {
        const value = answerData.value as MultiChoiceAnswerValue[];
        return value.map((answer) => {
          const possibleAnswer = possibleAnswers.find(
            (a) => a.id === answer.answerCode
          );

          return possibleAnswer ? possibleAnswer.label : answer.value;
        });
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        return [value.toString()];
      case AnswerDataTypeName.FILE: {
        const value = answerData.value as FileAnswerValue[];
        return value.map((a) => ({
          fileName: a.fileName ?? '',
          fileLink: a.fileLink ?? '',
          fileDownloadLink: a.fileId
            ? SaqUrls.file(
                answerResponse.siteQuestionnaireId,
                answerResponse.sectionId,
                questionId,
                a.fileId
              )
            : ''
        }));
      }
      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};

export const getQuestionRiskFromRecord = (
  record: Record<string, number> | undefined,
  allQuestions: QuestionResult[],
  allAnswers: Answers[],
  calculatedAnswers: CalculatedRiskAnswersResult | undefined
): QuestionRiskData[] => {
  const riskData = [];

  if (record) {
    for (const key in record) {
      const question = allQuestions?.find((question) => question.id === key);
      const answerResponse = allAnswers?.find(
        (answer: Answers) => answer.sectionId === question?.subsectionId
      );
      let calculatedAnswer: AnswerItem | undefined = undefined;

      if (!answerResponseHasAnswer(answerResponse, key)) {
        calculatedAnswer = calculatedAnswers?.answers.find(
          (answer) => answer.questionCode === key
        );
      }

      riskData.push({
        label: question?.label || key,
        tags: question?.tags ? question.tags.map((tag) => tag.subCategory) : [],
        score: record[key],
        answer: calculatedAnswer
          ? getCalculatedAnswer(
              question?.possibleAnswers ?? [],
              calculatedAnswer
            )
          : getAnswer(question?.possibleAnswers ?? [], answerResponse, key)
      });
    }
  }

  return riskData;
};

const answerResponseHasAnswer = (
  answerResponse: Answers | undefined,
  questionCode: string
): boolean => {
  return !!answerResponse?.answers?.find(
    (answer) => answer.questionCode === questionCode
  );
};

export const sortingScoreQuestions = (
  allQuestions: QuestionResult[],
  scoreQuestions: Record<string, number> | undefined
) => {
  if (scoreQuestions) {
    const sortedScoreQuestions: Record<string, number> = {};
    allQuestions.forEach((question) => {
      for (const key in scoreQuestions) {
        if (question.id === key) {
          sortedScoreQuestions[key] = scoreQuestions[key];
        }
      }
    });
    return sortedScoreQuestions;
  } else {
    return undefined;
  }
};

const getCalculatedAnswer = (
  possibleAnswers: Answer[],
  calculatedAnswer: AnswerItem
): string[] => {
  const answerData = calculatedAnswer.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        return [value.toString()];

      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};

export const getThresholdName = (key: string) => {
  switch (key) {
    case 'totalThreshold':
      return 'Total';
    case 'totalOffset':
      return 'Total';
    case 'HEALTH_AND_SAFETY':
      return 'Pillar: Health and Safety';
    case 'LABOUR_STANDARDS_AND_RIGHTS':
      return 'Pillar: Labour Standards';
    case 'BUSINESS_ETHICS':
      return 'Pillar: Business Ethics';
    case 'ENVIRONMENT':
      return 'Pillar: Environment';
    default:
      return '';
  }
};
